<template>
  <v-data-table
    v-model="selected"
    class="elevation-6"
    :headers="data.headers"
    :items="data.items"
    :items-per-page="5"
    :search="search"
    sort-by="frecuencia"
    show-select
    :sort-desc="true"
    item-key="nombre"
    @input="sendSelected()"
  >
    <template v-slot:top>
      <v-text-field
        v-model="search"
        label="Buscar"
        class="mx-4"
        prepend-icon="mdi-magnify"
      ></v-text-field>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    data: { type: Object, required: true },
  },
  data() {
    return {
      search: "",
      selected: [],
    };
  },
  methods: {
    sendSelected() {
      let entity = {
        name: this.data.entity,
        values: [],
      };
      for (let item in this.selected) {
        entity.values.push(this.selected[item].nombre);
      }
      // console.log(entity);
      this.$emit("selected", entity);
    },
  },
};
</script>
