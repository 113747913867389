<template>
  <v-card dark :color="darkTheme ? null : '#00A2F3'" elevation="6" class="twitter-card">
    <v-sheet
      :color="darkTheme ? null : '#00A2F3'"
      :class="
        dialog ? 'rounded-circle twitter-avatar-dialog' : 'rounded-circle twitter-avatar'
      "
    >
      <v-avatar size="65" class="ma-1">
        <v-img :alt="tweet.user.name" :src="tweet.user.profile_image_url_https"></v-img>
      </v-avatar>
    </v-sheet>
    <v-card-title class="pt-2 pb-0 my-0 text-body-1">
      <span :class="small ? 'twitter-card-title-truncate' : 'twitter-card-title '">
        {{ tweet.user.name }}
      </span>
      <template v-if="dialog">
        <v-spacer></v-spacer>
        <v-btn
          link
          icon
          :href="
            'https://twitter.com/' + tweet.user.screen_name + '/status/' + tweet.id_str
          "
          target="_blank"
          v-if="dialog"
        >
          <v-icon>mdi-share</v-icon>
        </v-btn>
        <v-btn icon @click="sendClose()"><v-icon>mdi-close</v-icon></v-btn>
      </template>
    </v-card-title>
    <v-card-subtitle class="py-0 mt-0 mb-3">
      <span :class="small ? 'twitter-card-title-truncate' : 'twitter-card-title '">
        @{{ tweet.user.screen_name }} - {{ createDate(tweet.created_at) }}
      </span>
    </v-card-subtitle>

    <v-card-text :class="dialog ? 'pt-3' : null" :style="small ? 'height: 70px' : null">
      <span :class="small ? 'twitter-card-text-truncate' : 'twitter-card-text'">
        <span v-html="cardText"></span>
      </span>
    </v-card-text>

    <v-card-actions class="py-0 my-0">
      <v-list-item>
        <v-row align="center">
          <v-chip small color="primary"> {{ tweet.sentimiento }} </v-chip>
          <v-spacer></v-spacer>
          <v-icon class="mr-1"> mdi-share-variant </v-icon>
          <span class="subheading mr-2">{{ tweet.retweet_count }}</span>
          <span class="mr-1">·</span>
          <v-icon class="mr-1"> mdi-heart </v-icon>
          <span class="subheading">{{ tweet.favorite_count }}</span>
        </v-row>
      </v-list-item>
    </v-card-actions>
    <div class="twitter-card-icon-background"><v-icon>mdi-twitter</v-icon></div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    tweet: { type: Object, required: true },
    small: { type: Boolean, default: false },
    dialog: { type: Boolean, default: false },
  },
  methods: {
    sendClose() {
      this.$emit("cardClose");
    },
    createDate(stringDate) {
      let tweetDate = new Date(stringDate).toLocaleDateString("es-MX");
      return tweetDate;
    },
  },
  computed: {
    ...mapState(["darkTheme"]),
    cardText() {
      let text = this.tweet.full_text;
      for (let i = 0; i < this.tweet.nlu.keywords.length; i++) {
        let entity = this.tweet.nlu.keywords[i].text;
        text = text.replace(entity, "<span class='myKeyword'>" + entity + "</span>");
      }
      return text;
    },
  },
};
</script>
