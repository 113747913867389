<template>
  <v-container>
    <!-- <pre v-if="selected"><code>{{selected}}</code></pre> -->
    <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
    <v-row>
      <v-col>
        <h2>Resultados de "{{ searchWord }}" en {{ nombre }}</h2>
      </v-col>
    </v-row>
    <v-row justify-sm="center" class="mb-2" v-if="createInfoCards" v-scrollfadein>
      <v-col
        cols="12"
        sm="12"
        md="3"
        v-for="(item, index) in createInfoCards"
        :key="index"
      >
        <InfoCard :info="item" />
      </v-col>
    </v-row>
    <v-row justify-sm="center" class="mb-2" v-if="createTablesInfo" v-scrollfadein>
      <v-col
        cols="12"
        sm="12"
        md="4"
        v-for="(item, index) in createTablesInfo"
        :key="index"
      >
        <DataTableSelect :data="item" @selected="addSearchItems($event)" />
      </v-col>
    </v-row>
    <v-row v-if="filteredCards">
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="(item, index) in filteredCards"
        :key="index"
        v-scrollfadein
        @click="showCardDialog(item)"
      >
        <TwitterCard :tweet="item" :small="true" v-if="nombre === 'Twitter'" />
        <DiscoveryCard :info="item" :small="true" v-else />
      </v-col>
    </v-row>
    <v-dialog max-width="700" v-model="dialogCard" class="">
      <TwitterCard :tweet="dialogCardInfo" :dialog="true" v-if="nombre === 'Twitter'" />
      <DiscoveryCard :info="dialogCardInfo" :dialog="true" v-else />
    </v-dialog>
  </v-container>
</template>

<script>
import InfoCard from "@/components/InfoCard";
import DataTableSelect from "@/components/DataTableSelect";
import TwitterCard from "@/components/TwitterCard";
import DiscoveryCard from "@/components/DiscoveryCard";
import { mapState } from "vuex";

export default {
  components: {
    InfoCard,
    DataTableSelect,
    TwitterCard,
    DiscoveryCard,
  },
  data() {
    return {
      selectedForSearch: {},
      dialogCard: false,
      dialogCardInfo: null,
      breadcrumbs: [
        {
          text: "Medios",
          disabled: false,
          to: "/medios",
        },
        {
          text: "Fuente",
          disabled: true,
        },
        {
          text: this.nombre,
          disabled: true,
        },
      ],
      infoCards: null,
      tableInfo: null,
      cardInfo: null,
    };
  },
  props: {
    nombre: { type: String, required: true },
    info: { type: Object, required: true },
  },
  methods: {
    addSearchItems(items) {
      switch (items.name) {
        case "Empresa":
          items.name = "company";
          break;
        case "Vacante":
          items.name = "person";
          break;
        case "Ubicación":
          items.name = "location";
          break;
      }
      this.selectedForSearch[items.name] = items.values;
      if (items.values.length === 0) {
        delete this.selectedForSearch[items.name];
      }
      this.filterCards();
      // console.log(this.selectedForSearch);
    },
    showCardDialog(card) {
      this.dialogCard = true;
      this.dialogCardInfo = card;
    },
    hideDialogCard(evt) {
      this.dialogCard = false;
      console.log(evt);
    },
    filterCards() {
      // console.log(this.sourceResults.datos);
      if (Object.keys(this.selectedForSearch).length > 0) {
        let filteredResults = [];
        let dataCards = this.sourceResults.datos;
        for (let item in dataCards) {
          let existe = false;
          let entities = dataCards[item].entities;
          for (let entity in entities) {
            if (entities[entity].datos.length > 0) {
              let filters = this.selectedForSearch[entities[entity].entity];
              for (let filter in filters) {
                if (entities[entity].datos.includes(filters[filter])) {
                  existe = true;
                }
              }
            }
          }
          if (existe) {
            filteredResults.push(dataCards[item]);
          }
        }
        // console.log(filteredResults);
        this.cardInfo = filteredResults;
      } else {
        this.cardInfo = this.sourceResults.datos;
      }
    },
  },
  computed: {
    ...mapState("account", ["sourceResults", "searchWord"]),
    createInfoCards() {
      return [
        {
          icon: "mdi-account-tie",
          color: "primary",
          name: "Candidatos",
          count: this.sourceResults.candidatos,
        },
        {
          icon: "mdi-domain",
          color: "success",
          name: "Vacantes",
          count: this.sourceResults.vacantes,
        },
        {
          icon: "mdi-earth",
          color: "warning",
          name: "Otros",
          count: this.sourceResults.otros,
        },
      ];
    },
    createTablesInfo() {
      return [
        {
          entity: this.sourceResults.entities[0].entity,
          headers: [
            {
              text: this.sourceResults.entities[0].entity.toUpperCase(),
              align: "start",
              value: "nombre",
            },
            {
              text: "MENCIONES",
              align: "end",
              value: "frecuencia",
            },
          ],
          items: this.sourceResults.entities[0].datos,
        },
        {
          entity: this.sourceResults.entities[1].entity,
          headers: [
            {
              text: this.sourceResults.entities[1].entity.toUpperCase(),
              align: "start",
              value: "nombre",
            },
            {
              text: "MENCIONES",
              align: "end",
              value: "frecuencia",
            },
          ],
          items: this.sourceResults.entities[1].datos,
        },
        {
          entity: this.sourceResults.entities[2].entity,
          headers: [
            {
              text: this.sourceResults.entities[2].entity.toUpperCase(),
              align: "start",
              value: "nombre",
            },
            {
              text: "MENCIONES",
              align: "end",
              value: "frecuencia",
            },
          ],
          items: this.sourceResults.entities[2].datos,
        },
      ];
    },
    filteredCards() {
      if (this.cardInfo != null) {
        return this.cardInfo;
      } else {
        return this.sourceResults.datos;
      }
    },
  },
  beforeMount() {
    if (this.info) {
      this.$store.commit("account/setSourceResults", this.info);
    }
  },
};
</script>
