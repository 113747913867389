<template>
  <v-card elevation="6" class="discovery-card">
    <v-card-title class="pt-2 pb-0 my-0 text-body-1">
      <span :class="small ? 'discovery-card-title-truncate' : 'discovery-card-title '">
        {{ info.origen_titulo }}
      </span>
      <template v-if="dialog">
        <v-spacer></v-spacer>
        <v-btn link icon :href="info.origen_url" target="_blank" v-if="dialog">
          <v-icon>mdi-share</v-icon>
        </v-btn>
        <v-btn icon @click="sendClose()"><v-icon>mdi-close</v-icon></v-btn>
      </template>
    </v-card-title>
    <v-card-subtitle class="py-0 mt-0 mb-3">
      <span :class="small ? 'discovery-card-title-truncate' : 'discovery-card-title '">
        {{ info.origen_medio }} <br />
        {{ info.origen_fecha }}
      </span>
    </v-card-subtitle>

    <v-card-text :class="dialog ? 'pt-3' : null" :style="small ? 'height: 70px' : null">
      <span :class="small ? 'discovery-card-text-truncate' : 'discovery-card-text'">
        <span v-html="cardText"></span>
      </span>
    </v-card-text>

    <v-card-actions class="py-0 my-0">
      <v-list-item>
        <v-row align="center">
          <v-chip small color="primary">
            {{ sentiment }}
          </v-chip>
          <v-spacer></v-spacer>
        </v-row>
      </v-list-item>
    </v-card-actions>
    <div class="discovery-card-icon-background"><v-icon>mdi-newspaper</v-icon></div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    info: { type: Object, required: true },
    small: { type: Boolean, default: false },
    dialog: { type: Boolean, default: false },
  },
  methods: {
    sendClose() {
      this.$emit("cardClose");
    },
  },
  computed: {
    ...mapState(["darkTheme"]),
    cardText() {
      let text = this.info.full_text;
      for (let i = 0; i < this.info.enrich.keywords.length; i++) {
        let entity = this.info.enrich.keywords[i].text;
        text = text.replace(entity, "<span class='myKeyword'>" + entity + "</span>");
      }
      return text;
    },
    sentiment() {
      switch (this.info.enrich.sentiment.document.label) {
        case "positive":
          return "Positivo";
        case "negative":
          return "Negativo";
        case "neutral":
          return "Neutro";
        default:
          return "";
      }
    },
  },
};
</script>
